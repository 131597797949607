@tailwind base;
@tailwind components;

svg {
	@apply w-full
        h-full;
}

section {
	@apply my-11;

	& header {
		@apply flex
            items-center
            justify-between
            cursor-pointer;

		& span {
			@apply text-sm;
		}
	}
	& h2 {
		@apply text-purple
            text-lg
            font-bold;
	}

	& main {
		@apply hidden;
	}

	&.active {
		& header button {
			@apply transform
                rotate-45;
		}

		& main {
			@apply block
                py-8;
		}
	}
}

ol {
	counter-reset: li;
	@apply my-8;

	& li {
		counter-increment: li;
		@apply relative
            pl-8
            py-1
            my-1;

		&::before {
			content: counter(li);
			@apply w-6
                h-6
                bg-white
                border
                border-purple
                rounded-full
                absolute
                top-px
                mt-px
                left-0
                text-center
                text-xs
                pt-1;
		}
	}
}

@tailwind utilities;
